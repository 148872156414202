'use strict';

var navigationToggle = function () {
    let navigationTrigger   = document.querySelectorAll('.js-navigationTrigger'),
        html                = document.querySelectorAll('html'),
        navigationLinks     = document.querySelectorAll('.js-navigationLink');

    navigationTrigger[0].addEventListener('click', function (e) {
        e.preventDefault();

        if (html[0].classList.contains('navOpen')) {
            html[0].classList.remove('navOpen');
        } else {
            html[0].classList.add('navOpen');
        }
    });

    [].forEach.call(navigationLinks, function (link) {
        link.addEventListener('click', function () {
            if (html[0].classList.contains('navOpen')) {
                html[0].classList.remove('navOpen');
            }
        });
    });
};

var stickyHeader = function () {
    var header          = document.querySelectorAll('.Header'),
        headerContainer = document.querySelectorAll('.Header-container'),
        sticky          = false,
        stickyPoint     = getDistance();

    function getDistance() {
        var topDistance = header[0].offsetTop;

        return topDistance;
    }

    header[0].style.height = headerContainer[0].offsetHeight + 'px';

    window.onscroll = function() {
        var distance    = getDistance() - window.pageYOffset;
        var offset      = window.pageYOffset;

        if ((distance <= 0) && !sticky) {
            header[0].classList.add('isSticky');
            sticky = true;
        } else if (sticky && (offset <= stickyPoint)) {
            header[0].classList.remove('isSticky');
            sticky = false;
        }
    }
};

navigationToggle();
stickyHeader();